form {
    .input-group {
        display: block;
        margin-bottom: 20px;
    }
    input {
        &.form-control {
            display: block;
            float: none;
            width: 100%;
            height: 65px;
            padding: 0 0 0 76px;
            font-size: 18px;
            line-height: 1.42857143;
            color: rgb(132, 132, 132);
            background-color: $bg-color1;
            background-image: none;
            border: none;
            border-radius: 4px;
            -webkit-box-shadow: none;
            box-shadow: none;
            -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
            -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
            transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;

            &[name=name] {
                background-image: url('../img/form-name.png');
                background-position: center left;
                background-repeat: no-repeat;
                background-size: contain;
            }
            &[name=company] {
                background-image: url('../img/form-company.png');
                background-position: center left;
                background-repeat: no-repeat;
                background-size: contain;
            }
            &[name=emailaddress] {
                background-image: url('../img/form-email.png');
                background-position: center left;
                background-repeat: no-repeat;
                background-size: contain;
            }
            &[name=phone] {
                background-image: url('../img/form-phone.png');
                background-position: center left;
                background-repeat: no-repeat;
                background-size: contain;
            }

            &.errorField {
                border: 2px solid $base-color1;
                background-color: rgb(249, 249, 249);

                &[name=name] {
                    background-image: url('../img/form-name-error.png');
                }
                &[name=company] {
                    background-image: url('../img/form-company-error.png');
                }
                &[name=emailaddress] {
                    background-image: url('../img/form-email-error.png');
                }
                &[name=phone] {
                    background-image: url('../img/form-phone-error.png');
                }
            }
        }
    }
    textarea {
        &.form-control {
            display: block;
            float: none;
            width: 100%;
            height: 145px;
            padding: 20px 20px 10px 76px;
            font-size: 18px;
            line-height: 1.42857143;
            color: rgb(132, 132, 132);
            background-color: $bg-color1;
            background-image: none;
            border: none;
            border-radius: 4px;
            -webkit-box-shadow: none;
            box-shadow: none;
            -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
            -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
            transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;

            &[name=message] {
                background-image: url('../img/form-message.png');
                background-position: top left;
                background-repeat: no-repeat;
                background-size: 65px;
            }

            &.errorField {
                border: 2px solid $base-color1;
                background-color: rgb(249, 249, 249);

                &[name=message] {
                    background-image: url('../img/form-message-error.png');
                }
            }
        }
    }
}