// Set breakpoints
@mixin breakpoint($point) {
  @if $point == xs {
    @media (min-width: 480px) { @content; }
  }
  @elseif $point == sm {
    @media (min-width: 768px) { @content; }
  }
  @else if $point == md {
    @media (min-width: 992px) { @content; }
  }
  @else if $point == lg {
    @media (min-width: 1200px)  { @content; }
  }
  @else if $point == lap {
    @media (min-width: 992px) and (max-width: 1450px) { @content; }
  }
}

@mixin breakpoint-max($point) {
  @if $point == sm {
    @media (max-width: 768px) { @content; }
  }
  @else if $point == md {
    @media (max-width: 992px) { @content; }
  }
  @else if $point == lg {
    @media (max-width: 1200px)  { @content; }
  }
}

// INCLUDE
// @include breakpoint(md) {...}