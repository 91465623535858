header.header-container {
  position: relative;
  width: 100%;
  height: 380px;
  max-height: 90vh;
  max-height: calc(100vh - 98px);

  &.dashboard-header {
      height: 630px;
      height: 75vh;
      max-height: 90vh;
      max-height: calc(100vh - 82px);

      @include breakpoint(xs) {
          height: 630px;
      }

      .align-middle {

          .align-middle-cell {

              h1, h2 {
                  color: $heading-font-color2;
                  font-family: $base-font-family;
                  font-size: 30px;
                  line-height: normal;
                  font-weight: 400;
                  background-color: $base-color1;
                  display: table;
                  padding: 10px 26px;
                  margin: 0 auto 10px auto;

                  @include breakpoint(xs) {
                      font-size: 40px;
                  }
                  @include breakpoint(sm) {
                      font-size: 50px;
                  }
                    @include breakpoint(md) {
                      font-size: 60px;
                    }
                  @include breakpoint(lg) {
                      font-size: 72px;
                  }
              }
          }
      }
  }

  .align-middle {
    display: table;
    width: 100%;
    height: 100%;
    text-align: center;

    .align-middle-cell {
      vertical-align: middle;
      //padding-bottom: 6.438rem;
      display: table-cell;

      @media (max-width: 767px) {
        padding-bottom: 43.938px;
      }

      @media (max-width: 567px) {
        padding-bottom: 5rem;
      }

      @media (max-width: 320px) {
        padding-bottom: 3.938rem;
      }

      h1, h2 {
        color: $heading-font-color2;
        font-family: $base-font-family;
        font-size: 30px;
        line-height: normal;
        font-weight: 400;
        background-color: $base-color1;
        display: table;
        padding: 5px 15px;
        margin: 0 auto 10px auto;


        @include breakpoint(sm) {
          font-size: 40px;
            padding: 10px 26px;
        }
          @include breakpoint(md) {
              font-size: 48px;
          }


      }
      h3 {
        font-family: $base-font-family;
        font-size: 17px;
        font-weight: 400;
        line-height: 50px;
        background-color: $body-bg-color;
        display: table;
        padding: 0 10px;
        margin: 0 auto 5px auto;

          @include breakpoint(xs) {
              font-size: 24px;
          }
        @include breakpoint(sm) {
          font-size: 29px;
            padding: 10px 18px;
        }

        &.team {
          background-color: $base-color1;
          color: $heading-font-color2;
        }

      }
      p {
        margin: 20px 0 0 0;
      }
      .button {
          margin-left: 5px;
          margin-right: 5px;

          &:first-of-type {
              margin-left: 0;
              margin-right: 5px;
          }
          &:last-of-type {
              margin-left: 5px;
              margin-right: 0;
          }
      }
      .indicator {
        display: block;
        cursor: pointer;
        width: 5rem;
        height:  3.125rem;
        background: $body-bg-color url('../img/icon-arrow-down.svg') 50% 50% no-repeat;
        background-size: 28px 15px;
        position: absolute;
        bottom:0;
        left:50%;
        margin-left: -2.5rem;
        @include transition(all, 300ms);

        @media (max-width: 767px) {
          width: 5rem;
          height: 2.125rem;
          background: $body-bg-color url('../img/icon-arrow-down.svg') 50% 50% no-repeat;
        }

        @media (max-width: 567px) {
          width: 5rem;
          height:  3.125rem;
          background: $body-bg-color url('../img/icon-arrow-down.svg') 50% 50% no-repeat;
        }

        &:hover {
          background-position: 50% 75%;
        }
      }
    }
  }

  &.team {

    .button-play {
      margin: 0 auto 115px auto;
      width: 120px;
      height:120px;
      background-size: 40px 53px;
    }
  }

  &.rick {
    background-image: url('../img/team-rick-large.jpg');
    @media(max-width: 767px) and (min-width: 567px) {
      background-size: 100%;
      background-position: center;
      height: 250px;
    }
  }
  &.satis {
    background-image: url('../img/team-satis-large.jpg');
    @media(max-width: 767px) and (min-width: 567px) {
      background-size: 100%;
      background-position: center;
      height: 250px;
    }
  }
  &.michael {
    background-image: url('../img/team-michael-large.jpg');
    @media(max-width: 767px) and (min-width: 567px) {
      background-size: 100%;
      background-position: center;
      height: 250px;
    }
  }
  &.robbie {
    background-image: url('../img/team-robbie-large.jpg');
    @media(max-width: 767px) and (min-width: 567px) {
      background-size: 100%;
      background-position: center;
      height: 250px;
    }
  }
}