// Blocks
@mixin block($color: $base-font-color2) {
  position: relative;
  box-shadow: 0 0.188rem 0 rgba(0, 0, 0, 0.11);
  border-radius: 0.375rem;
  background-color: $color;

  display: table;
  padding: 25px;
  width: 100%;
  margin-bottom: 30px;
  background-size: cover;
  background-position: 50% 20%;
  background-repeat: no-repeat;

  .vc {
    display: table-cell;
    vertical-align: middle;
  }
  .vb {
    display: table-cell;
    vertical-align: bottom;
  }
  .vt {
    display: table-cell;
    vertical-align: top;
  }

  @include breakpoint(md) {
    background-position: center center;
  }
}

.block-type-1 {
  @include block;
}
.block-type-2 {
  @include block($base-color1);
}
.block-type-3 {
  @include block($bg-color1);
}

.block-type-1,
.block-type-2,
.block-type-3{

  h3 {
    font-size: 30px;
    font-weight: 700;
  }
  h4 {
    font-size: 1.688rem;
  }
  .number {
    font-family: $base-font-family;
    color: $base-color1;
    font-size: 3.5rem;
    font-weight: 700;
    line-height: normal;

    @include breakpoint(sm) {
      font-size: 4.5rem;
    }
  }
}

.block-type-2 {

  h3,
  h4,
  .number,
  p{
    color: $base-font-color2;
  }
}
.block-type-3 {
  margin: 3.125rem 0;
  padding: 2.5rem;

  h4 {
    margin-bottom: 2rem;
  }
}