@mixin button($font-size: 1.313rem) {
	position: relative;
	width: auto;
	height: auto;
	border: none;
	background-color: $base-color2;
	color: rgb(0, 75, 109);
	transition: all .3s;
	outline: none;
	outline-style: none;
	display: inline-block;
	border-radius: 0.375rem;
	font-family: $heading-font-family;
	font-size: $font-size !important;
	font-weight: 600 !important;
	text-transform: none !important;
	padding: 15px 34px 15px 20px !important;
	line-height: 20px;

	@include breakpoint-max(sm) {
		font-size: 1rem !important;
	}

	&:after {
		position: absolute;
		content: '';
		top: 50%;
		margin-top: -6px;
		right: 12px;
		width: 7px;
		height: 13px;
		background-image: url('../img/icon-arrow-right.svg');
		background-repeat: no-repeat;
		background-position: center right;
		background-size: contain;
	}

	&:hover {
		background-color: darken($base-color2, 5%) !important;
		color: rgb(0, 75, 109);
	}
	&:visited {
		color: rgb(0, 75, 109);
	}
}
.button-sm {
	@include button(0.875rem);
	font-family: $base-font-family !important;
	padding: 8px 34px 8px 20px !important;
}


.button {
	@include button;
}
.button-lg {
	@include button(1.5rem);
}

.button-back {
	@include button();
	padding: 15px 20px 15px 34px !important;

	&:after {
		position: absolute;
		content: '';
		top: 50%;
		margin-top: -6px;
		left: 12px;
		width: 7px;
		height: 13px;
		background-image: url('../img/icon-arrow-left.svg');
		background-repeat: no-repeat;
		background-position: center right;
		background-size: contain;
	}
}

.button-play {
	cursor: pointer;
	display: block;
	width: 3.938rem;
	height: 3.938rem;
	box-shadow: 0 0 2.5rem 0.875rem rgba(0, 0, 0, 0.43);
	background: $base-font-color2 url('../img/icon-play.svg') 58% 50% no-repeat;
	background-size: 21px 28px;
	@include border-radius(50%);
	@include transition(all, 300ms);

	&:hover {
		box-shadow: 0 0 2.5rem 0.875rem rgba(0, 0, 0, 0.7);
		background-size: 26px 33px;
	}
}