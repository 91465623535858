// Set accessibility testing true/false
$testing: false;
// Set font smoothing true/false
$smoothing: true;
// Set default gutter width (settings custom gutter width in _gutter.scss)
// True = bootstrap gutter width
$defaultGutter: true;

// Import plugins
@import "plugins/animations.css";
@import "plugins/jquery.fancybox.css";
@import "plugins/slick";

// Import mixins
@import "mixins/abs-pos";
@import "mixins/border-radius";
@import "mixins/box-sizing";
@import "mixins/breakpoints";
@import "mixins/keyframes";
@import "mixins/opacity";
@import "mixins/transition";
@import "mixins/gutter";

// Import settings
@import "settings/testing";
@import "settings/colors";
@import "settings/font";
@import "settings/links";
@import "settings/reset";
@import "settings/gutter";

// Import elements
@import "elements/button";
@import "elements/blocks";
@import "elements/forms";

// Import layouts
@import "layout/nav";
@import "layout/header";
@import "layout/footer";

// Body
html {
	font-size: 16px;
}
body {
	background-color: 	$body-bg-color;
	font-family: 		$base-font-family;
	font-size: 			$base-font-size;
	font-weight: 		$base-font-weight;
	color: 				$base-font-color1;
	padding-top: 		5.125rem;

	&.sitekick {
		padding-top: 13rem;
	}
}

// Headings
h1,h2,h3,h4,h5,h6 {
	font-family: 		$heading-font-family;
	font-weight: 		$heading-font-weight;
	color: 				$heading-font-color1;
}

h1 {
	@include heading-size(h1);
	margin-bottom: 20px;

	@include breakpoint(sm) {
		margin-bottom: 3.125rem;
	}
}
h2 {
	@include heading-size(h2);
	margin-bottom: 20px;

	@include breakpoint(sm) {
		margin-bottom: 40px;
	}
}
h3, h3 a, h3 a:hover, h3 a:visited {
	@include heading-size(h3);
}
h4 {
	@include heading-size(h4);
}
h5 {
	@include heading-size(h5);
}
h6 {
	@include heading-size(h6);
}
p {
	margin-bottom: 30px;

	&:last-of-type {
		margin-bottom: 0;
	}
}
// Links
.sitekick a:hover, .sitekick a:active, .sitekick a:focus {
	color: inherit;
}
a {
	// Default
	color: $link-color-default;
	text-decoration: $link-decoration-default;
	font-size: $link-size-default;

	// Hover
	&:hover {
		color: $link-color-hover;
		text-decoration: $link-decoration-hover;
		font-size: $link-size-hover;
	}

	// Active
	&:active {
		color: $link-color-active;
		text-decoration: $link-decoration-active;
		font-size: $link-size-active;
	}

	// Visited
	&:visited {
		color: $link-color-visited;
		text-decoration: $link-decoration-visited;
		font-size: $link-size-visited;
	}
}
img {
  max-width: 100%;
}
hr {
	margin-top: 2rem;
	margin-bottom: 2rem;
	border: 0;
	border-top: 1px solid rgba(255, 255, 255, 0.2);
}
.no-gutter > [class*='col-'] {
	padding-right:0;
	padding-left:0;
}
.rounded {
	border-radius: 0.375rem;
    margin-bottom: 30px;
	width:100%;

	@include breakpoint(sm) {
		width: auto;
	}
}
.navbar-fixed-bottom .navbar-collapse, .navbar-fixed-top .navbar-collapse {
	max-height: inherit;
}
// JS specific styles
// Equalheight
.js-eqh {

  @include breakpoint-max(sm) {
    height: auto !important;
  }
}
div.intro {
  font-size: 1.313rem;
  font-weight: $weight-regular;
  margin-bottom: 6.25rem;

  h3 {

    a {
      text-decoration: underline;
      margin-top: 50px;
      display: inline-block;
    }
  }
}
// Text link
.link {
	font-size: 1.875rem;
	font-weight: $weight-regular;
	margin-top: 3rem;
	text-decoration: underline;
	display: inline-block;

	&:hover {
		font-size: 1.875rem;
		color: darken($base-color1, 10%);
	}
}
// Navigational link
a.nav-link,
a.nav-link:visited {
	opacity: 0.7;
	color: $nav-link;
	font-family: $heading-font-family;
	font-size: 1.125rem;
	font-weight: $weight-regular;
	text-decoration: underline;
	margin-bottom: 2.813rem;
	display: inline-block;
}
.link-back {
	font-size: 17px;
	font-weight: 600;
	line-height: 45px;
	text-decoration: underline;
	margin: 0 0 20px 0;
	background: transparent url('../img/icon-arrow-left-blue.svg') left center no-repeat;
	display: inline-block;
	padding-left: 20px;
	background-size: 7px 11px;

	@include breakpoint(sm) {
		float: right;
		margin: -3.125rem 0 0 0;
	}

	&:hover, &:visited {
		font-size: 17px;
		font-weight: 600;
		line-height: 45px;
		text-decoration: none;
	}
}