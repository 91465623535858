.navbar-default.navbar-custom {
    box-shadow: 0 0 0.688rem rgba(0, 0, 0, 0.17);
    background-color: rgb(255, 255, 255);
    min-height: 5.125rem;
    border: none;


    .contactbar {
        background-color: #f0f0f0;
        padding: 10px 0;

        .socialmedia {

            .icon-facebook {
                background: url('../img/icon-facebook.svg') center center no-repeat;
                background-size: contain;
                display: inline-block;
                width: 28px;
                height: 28px;
                text-indent: -9999999px;
                margin-right: 5px;
                opacity: 0.7;
                -webkit-transition: all 200ms;
                -moz-transition: all 200ms;
                -ms-transition: all 200ms;
                -o-transition: all 200ms;
                transition: all 200ms;

                &:hover {
                    opacity: 1;
                    -webkit-transition: all 200ms;
                    -moz-transition: all 200ms;
                    -ms-transition: all 200ms;
                    -o-transition: all 200ms;
                    transition: all 200ms;
                }
            }

            .icon-twitter {
                background: url('../img/icon-linkedin.svg') center center no-repeat;
                background-size: contain;
                display: inline-block;
                margin-right: 6px;
                width: 28px;
                height: 28px;
                text-indent: -9999999px;
                opacity: 0.7;
                -webkit-transition: all 200ms;
                -moz-transition: all 200ms;
                -ms-transition: all 200ms;
                -o-transition: all 200ms;
                transition: all 200ms;

                &:hover {
                    opacity: 1;
                    -webkit-transition: all 200ms;
                    -moz-transition: all 200ms;
                    -ms-transition: all 200ms;
                    -o-transition: all 200ms;
                    transition: all 200ms;
                }
            }
            .icon-instagram{
                background: url('../img/icon-instagram.svg') center center no-repeat;
                background-size: contain;
                display: inline-block;
                width: 28px;
                height: 28px;
                text-indent: -9999999px;
                opacity: 0.7;
                -webkit-transition: all 200ms;
                -moz-transition: all 200ms;
                -ms-transition: all 200ms;
                -o-transition: all 200ms;
                transition: all 200ms;

                &:hover {
                    opacity: 1;
                    -webkit-transition: all 200ms;
                    -moz-transition: all 200ms;
                    -ms-transition: all 200ms;
                    -o-transition: all 200ms;
                    transition: all 200ms;
                }
            }
        }


        .tel {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            span {
                color: rgb(0, 138, 200);
                font-size: 18px;
                font-weight: 400;
                display: block;
                padding-top: 2px;
            }

            .button-sm {
                margin-left: 30px;
                margin-right: -15px;
                float: right;
            }
        }
    }

    .navbar-brand {
        height: auto;
        padding: 17px 10px;

        @include breakpoint(md) {
            padding: 20px 0;
        }

        img {
            display: block;
            height: 3rem;
        }
    }

    .navbar-toggle {
        margin-top: 25px;
    }

    .navbar-nav {
        margin-top: 0;
        padding: 10px;
        background: white;
        width: 100%;

        height: calc(100vh - 90px);
        overflow-y: auto;

        @include breakpoint(md) {
            padding: 0;
            margin-top: 1.45rem;
            width: auto;
            height: auto;
            overflow: visible;
        }

        li {
            position: relative;

            &.has-sub {
                background: url('../img/icon-arrow-down.svg') right 15px no-repeat;
                background-size: 9px 5px;
                margin-right: 10px;

                @include breakpoint(md) {
                    background: url('../img/icon-arrow-down.svg') right center no-repeat;
                    background-size: 9px 5px;
                }
            }

            a {
                font-family: $heading-font-family;
                font-size: 1rem;
                font-weight: 400;
                text-transform: uppercase;
                color: $base-font-color1;
                padding: 15px 15px;

                &.button {
                    margin-left: 0;

                    @include breakpoint(md) {
                        margin-left: 10px;
                    }
                    @include breakpoint(lg) {
                        margin-left: 10px;
                        padding: 15px 25px;
                        font-size: 1.063rem;
                    }
                }

                // portal link
                &.portal {
                    margin-top: 5%;

                    //laptop smaller fz to prevent ul overflow
                    @include breakpoint(lap) {
                        font-size: .65rem !important;
                    }
                    &:visited {
                        color: rgb(0, 75, 109);
                    }
                    &:focus {
                        background-color: darken($base-color2, 5%) !important;
                    }
                }
            }

            .sub {
                display: none;
                background-color: rgb(255, 255, 255);
                opacity: 0;
                position: relative;
                top: 0;
                left: 0;
                padding: 20px 0 0 0;
                width: auto;
                min-width: 200px;
                @include transition(all, 300ms);

                @include breakpoint(md) {
                    position: absolute;
                    top: 50px;
                    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.05);
                }

                li {

                    padding-bottom: 10px;
                    margin-bottom: 10px;

                    @include breakpoint(md) {
                        border-bottom: 1px solid #f0f0f0;
                    }

                    &:last-child {
                        margin-bottom: 0;
                        border: none;
                    }

                    a {
                        display: block;
                        padding: 0 20px;
                        font-size: 15px;

                        @include breakpoint(md) {
                            font-size: 1rem;
                        }
                    }
                }

                &.expand {
                    display: block !important;
                    opacity: 1 !important;
                }
            }

            &.active {

                a {
                    background: none;
                    color: $base-color1;

                    &.button {
                        background-color: $base-color2;
                    }
                }
            }

            &:hover {

                a {
                    background: none;
                    color: $base-color1;
                }

                .sub {
                    @include breakpoint(md) {
                        display: block;
                        opacity: 1;
                    }

                    @include transition(all, 300ms);

                    li {

                        a {
                            color: $base-font-color1;

                            &:hover {
                                color: $base-color1;
                            }

                        }

                        &.active {
                            a {
                                color: $base-color1;
                            }
                        }
                    }
                }
            }
        }
    }

    > .container {

        @include breakpoint(sm) {
            width: 100%;
        }
        @include breakpoint(md) {
            width: 970px;
        }
        @include breakpoint(lg) {
            width: 1170px;
        }
    }
}

.submenu {
    box-shadow: 0 0 11px rgba(0, 0, 0, 0.17);
    background-color: $base-color1;
    position: fixed;
    top: 136px;
    left: 0;
    width: 100%;
    height: auto;
    z-index: 3;

    > .container {

        @include breakpoint-max(md) {
            width: 100%;
        }
    }

    ul {

        @include breakpoint-max(md) {
            text-align: center;
        }

        li {
            display: inline-block;
            margin-left: 10px;

            @include breakpoint(md) {
                margin-left: 25px;
            }

            &:first-child {
                margin-left: 0;
            }

            a, a:visited, a:focus {
                display: block;
                padding: 10px 0;
                color: $base-font-color2;
                font-size: 14px;
                font-weight: 400;
                text-transform: uppercase;

                @include breakpoint(md) {
                    font-size: 16px;
                }

                &:hover, &.active {
                    color: $base-color2;
                }
            }
        }
    }
}

.sitekick .submenu {
    top: 209px;
}

// Override bootstrap collapse
@media (max-width: 991px) {
    .navbar-header {
        float: none;
    }
    .navbar-left, .navbar-right {
        float: none !important;
    }
    .navbar-toggle {
        display: block;
        border: none;
        border-radius: 0px;
    }
    .navbar-collapse {
        border-top: 1px solid transparent;
        box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
    }
    .navbar-fixed-top {
        top: 0;
        border-width: 0 0 1px;
    }
    .navbar-collapse.collapse {
        display: none !important;
    }
    .navbar-nav {
        float: none !important;
        margin-top: 7.5px;
    }
    .navbar-nav > li {
        float: none;
    }
    .navbar-nav > li > a {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .collapse.in {
        display: block !important;
    }
}

// Sitekick overrides
body.sitekick .navbar-custom {
    margin-top: 70px;
}

// Overrides for Werken bij page
#work_at {
    .icon-facebook {
        background: url('../../img/icon-facebook.png') center center no-repeat;
    }

    .icon-twitter {
        background: url('../../img/icon-twitter.png') center center no-repeat;
    }

    .has-sub {
        background: url('../../img/icon-arrow-down.svg') right center no-repeat;
        background-size: 9px 5px;
    }
}
