@if ($testing == true) {

	// Check if all images have an alt attribute
	img:not([alt]) {
		outline: .2em red solid;
	}

	// Check if all input fields have an type attribute
	input:not([type]) {
		outline: .2em red solid;
	}
}