@if ($defaultGutter == false) {

	// Gutter width
	$gutter-xs: 30px;
	$gutter-sm: 30px;
	$gutter-md: 30px;
	$gutter-lg: 30px;

	// Mobile devices (mobiel, <768px)
	div[class^="col-"]{padding-left: $gutter-xs /2!important; padding-right:$gutter-xs /2!important;}

	// Small devices (tablets, 768px and up) 
	@media (min-width: 768px) 
	{ 
	    div[class^="col-"]{padding-left:$gutter-sm /2!important; padding-right:$gutter-sm /2!important;}
	}

	// Medium devices (desktops, 992px and up) 
	@media (min-width: 992px) 
	{   
	    div[class^="col-"]{padding-left:$gutter-md /2!important; padding-right:$gutter-md /2!important;}
	}

	// Large devices (large desktops, 1200px and up) 
	@media (min-width: 1200px) 
	{   
	    div[class^="col-"]{padding-left:$gutter-lg /2!important; padding-right:$gutter-lg /2!important;}
	}

}