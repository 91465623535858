// Imports
@import "general";

img {
    width: auto;
    height: auto;
}

// Section
.mtop {

    @include breakpoint(md) {
        margin-top: -110px;
    }
}

.page-row-blocks {

    .text-center {

        p {
            text-align: center;
        }
    }

    p {
        text-align: justify;
        line-height: 31px;
    }
}

.larger {
    p {
        font-size: 21px;
    }
}

ul.list {
    margin: 0 0 0 20px;
    list-style: disc;
}

section {
    position: relative;
    width: 100%;
    padding: 2rem 0;

    @include breakpoint(sm) {
        padding: 3.25rem 0;
    }

    &.intro {
        p {
            font-size: 1.063rem;
            line-height: 1.938rem;
        }

        a {
            text-decoration: underline;
        }

        @media (max-width: 567px) {
            h1 {
                font-size: 2.3rem;
            }
        }
    }

    &.sectors {
        background: $base-color1 url('../img/bg-sectors.jpg') center center no-repeat;
        background-size: cover;


        ul {
            margin-top: 30px;

            li {
                width: 100%;
                float: left;
                padding: 0 0 30px 0;

                @include breakpoint(xs) {
                    width: 50%;
                    padding-right: 30px;

                    &:nth-child(2n + 2) {
                        padding-right: 0;
                    }
                }
                @include breakpoint(sm) {
                    width: 33.333%;

                    &:nth-child(2n + 2) {
                        padding-right: 30px;
                    }

                    &:nth-child(3n + 3) {
                        padding-right: 0;
                    }
                }


                h3 {
                    display: block;
                    padding: 30px;
                    -webkit-border-radius: 6px;
                    -moz-border-radius: 6px;
                    border-radius: 6px;
                    box-shadow: 0 3px 0 rgba(0, 0, 0, 0.11);
                    background-color: $base-color1;
                    color: rgb(255, 255, 255);
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 37px;

                    @include breakpoint(sm) {
                        font-size: 25px;
                    }
                }

            }
        }

    }

    &.callout {
        background: $base-color1;
        //background-size: cover;
        color: $base-font-color2;
        padding: 2rem 0;

        > div {
            position: relative;
            z-index: 2;
        }

        @include breakpoint(sm) {
            padding: 3.25rem 0;

            &:before {
                position: absolute;
                top: 0;
                left: 0;
                content: '';
                background: transparent url('../img/bg-callout.jpg') center center no-repeat;
                z-index: 0;
                width: 50%;
                height: 100%;
                background-size: cover;
            }

            &:after {
                content: "";
                display: block;
                position: absolute;
                top: 0;
                right: 50%;
                width: 25%;
                height: 100%;
                background: transparent url('../img/gradient.png') right center repeat-y;
                background-size: contain;
                z-index: 1;
            }
        }

        h3 {
            color: $base-font-color2;
            margin-bottom: 24px;

            @include breakpoint(md) {
                font-size: 42px;
            }
        }

        p {
            text-align: left;
            line-height: 31px;
            margin-bottom: 24px;
        }

    }

    &.marks {
        background-color: rgb(255, 255, 255);
        padding: 55px 0;

        img {
            margin-bottom: 10px;
            @include breakpoint(sm) {
                margin-bottom: 0;
            }
        }
    }

    &.bg-color-1 {
        background-color: $bg-color1;

        @media (max-width: 567px) {
            h2 {
                font-size: 2.3rem;
            }
        }
        @media (max-width: 1200px) {
            h4 {
                font-size: 1.2rem;
            }
        }
        @media (max-width: 767px) {
            h4 {
                font-size: 1.688rem;
            }
        }

        p {
            font-size: 1.063rem;
        }

        .img-cable {
            height: 262px;
            @media (max-width: 1200px) {
                height: 238px;
            }
            @media (max-width: 767px) {
                height: 173px;
            }
        }
    }

    &.team {
        h2 {
            text-align: center;
            margin-bottom: 40px;
        }

        p {
            color: $base-font-color1;
            font-size: 21px;
            text-align: center;
            line-height: 30px;
            letter-spacing: 1px;
            margin-bottom: 40px;
        }

        h3 {
            font-size: 1.875rem;
            font-weight: $weight-bold;
            line-height: 2.063rem;
            margin-bottom: 10px;
        }

        .button-sm {
            margin-bottom: 0.625rem;
        }

        .button-play {
            position: absolute;
            bottom: 1.25rem;
            right: 1.563rem;
        }

        .block-type-2 {
            min-height: 330px;

            p {
                color: $base-font-color2;
                font-size: 1.313rem;
                font-weight: $weight-regular;
                line-height: 1.5rem;
                text-align: left;
                letter-spacing: normal;
                margin-bottom: 10px;
            }
        }
    }

    &.vacancies {
        @media (max-width: 567px) {
            h2 {
                font-size: 2.3rem;
            }
        }

        p {
            font-size: 1.063rem;
        }

        .block-type-2 {

            h3 {
                font-size: 1.8rem;
                line-height: 2.313rem;
                margin-bottom: 100px;
                @media (max-width: 1200px) {
                    font-size: 1.4rem;
                }
                @media (max-width: 375px) {
                    font-size: 1.575rem;
                }
                @media (max-width: 350px) {
                    font-size: 1.375rem;
                }
            }

            .button {
                position: absolute;
                bottom: 30px;
                left: 25px;
            }
        }
    }

    &.vacancy {
        padding-top: 3.125rem;

        h1 {
            font-size: 3rem;
        }

        ul {

            li {
                position: relative;
                font-size: 1.063rem;
                padding-left: 2.5rem;
                line-height: 1.938rem;

                &:before {
                    position: absolute;
                    top: 8px;
                    left: 8px;
                    width: 9px;
                    height: 16px;
                    content: "";
                    display: block;
                    background: transparent url('../img/icon-arrow-right-blue.svg') top center no-repeat;
                    background-size: 9px 16px;
                }
            }
        }
    }

    &.about {

        h2 {
            font-size: 2.25rem;
            margin-bottom: 2rem;
        }

        p {
            font-size: 1.063rem;
        }
    }

    @include breakpoint(sm) {
        padding: 6.25rem 0;
    }

    &.specialisms {
        background-color: $bg-color1;
        padding-bottom: 70px;

        .specialisms-item {

            .block-type-2 {
                min-height: 330px;

                &.specialisms-item-6 {
                    background-image: url('../img/bg-specialism-1.jpg');
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: cover;

                    &:after {
                        position: absolute;
                        top: 30px;
                        left: 30px;
                        width: 82px;
                        height: 52px;
                        content: '';
                        background: transparent url('../img/icon-electro.svg') center center no-repeat;

                        @media(max-width: 992px) {
                            left: inherit;
                            right: 30px;
                            top: inherit;
                            bottom: 20px;
                        }
                    }
                }

                &.specialisms-item-7 {
                    background-image: url('../img/bg-specialism-2.jpg');
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: cover;

                    &:after {
                        position: absolute;
                        top: 30px;
                        left: 30px;
                        width: 82px;
                        height: 52px;
                        content: '';
                        background: transparent url('../img/icon-fire.svg') center center no-repeat;

                        @media(max-width: 992px) {
                            left: inherit;
                            right: 30px;
                            top: inherit;
                            bottom: 20px;
                        }
                    }
                }

                &.specialisms-item-8 {
                    background-image: url('../img/bg-specialism-3.jpg');
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: cover;

                    &:after {
                        position: absolute;
                        top: 30px;
                        left: 30px;
                        width: 82px;
                        height: 52px;
                        content: '';
                        background: transparent url('../img/icon-cctv.svg') center center no-repeat;

                        @media(max-width: 992px) {
                            left: inherit;
                            right: 30px;
                            top: inherit;
                            bottom: 20px;
                        }
                    }
                }

                &.specialisms-item-9 {
                    background-image: url('../img/bg-specialism-4.jpg');
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: cover;

                    &:after {
                        position: absolute;
                        top: 30px;
                        left: 30px;
                        width: 82px;
                        height: 52px;
                        content: '';
                        background: transparent url('../img/icon-domotica.svg') center center no-repeat;

                        @media(max-width: 992px) {
                            left: inherit;
                            right: 30px;
                            top: inherit;
                            bottom: 20px;
                        }
                    }
                }

                &.specialisms-item-26 {
                    background-image: url('../img/bg-specialism-5.jpg');
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: cover;

                    &:after {
                        position: absolute;
                        top: 30px;
                        left: 30px;
                        width: 82px;
                        height: 52px;
                        content: '';
                        background: transparent url('../img/icon-burglar.svg') center center no-repeat;

                        @media(max-width: 992px) {
                            left: inherit;
                            right: 30px;
                            top: inherit;
                            bottom: 20px;
                        }
                    }
                }

                &.specialisms-item-10 {
                    background-image: url('../img/bg-specialism-6.jpg');
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: cover;

                    &:after {
                        position: absolute;
                        top: 30px;
                        left: 30px;
                        width: 82px;
                        height: 52px;
                        content: '';
                        background: transparent url('../img/icon-service.svg') center center no-repeat;

                        @media(max-width: 992px) {
                            left: inherit;
                            right: 30px;
                            top: inherit;
                            bottom: 20px;
                        }
                    }
                }

                .vb {

                    h3 {
                        font-size: 30px;
                        line-height: 33px;
                        text-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
                        color: rgb(255, 255, 255);
                        margin-bottom: 20px;
                    }
                }

            }

        }
    }

    &.projectcategories {
        background: url('../img/bg-projects.jpg') center center no-repeat;
        background-size: cover;

        .block-type-2 {

            h3 {
                margin-bottom: 10px;
            }
        }
    }

    .projects {

        margin-bottom: 50px;

        &.news {
            margin-bottom: 0;
        }

        .projects-item {


            .block-type-2 {

                @media (max-width: 992px) {
                    height: auto !important;
                    min-height: 100px !important;
                }

                &:after {
                    position: absolute;
                    content: '';
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 41%, rgba(0, 0, 0, 0.07) 48%, rgba(0, 0, 0, 0.6) 100%);
                    z-index: 0;
                }

                .vb {
                    position: relative;
                    z-index: 1;
                }

                h3 {
                    text-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
                    margin-bottom: 20px;

                    @media (max-width: 1200px) {
                        font-size: 22px;
                    }
                }

            }
        }
    }

    &.project {
        padding-bottom: 0;

        p {
            line-height: 31px;
        }

        .slider {
            box-shadow: 0 0 9px rgba(0, 0, 0, 0.17);
            border: 10px solid rgb(255, 255, 255);

            .slick-prev {
                position: absolute;
                z-index: 1;
                top: 50%;
                margin-top: -30px;
                left: 0;
                width: 60px;
                height: 62px;
                border-radius: 0 6px 6px 0;
                background: rgb(0, 133, 201) url('../img/icon-arrow-left-white.svg') center center no-repeat;
                background-size: 14px 23px;
                border: none;
                text-indent: -999999px;
                @include transition(all, 300ms);

                &:hover {
                    @include transition(all, 300ms);
                    width: 50px;
                }
            }

            .slick-next {
                position: absolute;
                z-index: 1;
                top: 50%;
                margin-top: -30px;
                right: 0;
                width: 60px;
                height: 62px;
                border-radius: 6px 0 0 6px;
                background: rgb(0, 133, 201) url('../img/icon-arrow-right-white.svg') center center no-repeat;
                background-size: 14px 23px;
                border: none;
                text-indent: -999999px;
                @include transition(all, 300ms);

                &:hover {
                    @include transition(all, 300ms);
                    width: 50px;
                }
            }
        }
    }

    &.teammembers {
        padding: 0 0 50px 0;

        .teammember {

            margin-bottom: 30px;
            @include breakpoint(sm) {
                margin-bottom: 0;
            }

            .inner {
                position: relative;
                margin-bottom: 20px;

                img {
                    width: 100%;
                    -webkit-border-radius: 5px;
                    -moz-border-radius: 5px;
                    border-radius: 5px;
                    @include transition(all, 300ms);
                }

                .email {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;

                    img {
                        position: absolute;
                        display: block;
                        bottom: 20px;
                        right: 20px;
                        width: 50px;
                        height: 50px;
                        @include transition(all, 300ms);
                    }
                }
            }

            h3 {
                font-size: 24px;
                margin-bottom: 5px;
            }

            p {
                line-height: normal;
                text-align: left;
            }

            &:hover {
                .inner {
                    img {
                        opacity: 0.9;
                        @include transition(all, 300ms);
                    }

                    .email {
                        img {
                            position: absolute;
                            display: block;
                            bottom: 20px;
                            right: 20px;
                            width: 55px;
                            height: 55px;
                            @include transition(all, 300ms);
                        }
                    }
                }
            }
        }
    }

    &.map {
        padding-bottom: 0;

        &:after {
            position: absolute;
        }

        #map {
            margin-top: 10px;
            width: 100%;
            height: 430px;
            max-height: 80vh;
            box-shadow: 0 0 9px rgba(0, 0, 0, 0.17);
            border: 10px solid rgb(255, 255, 255) !important;
        }
    }

    &.contact {
        padding: 0 0 60px 0;

        hr {
            margin-top: 60px;
            margin-bottom: 60px;
            border: 0;
            border-top: 1px solid $bg-color1;
        }
    }
}

// Video block
section.video-block {
    width: 100%;
    background-color: #f0f0f0;
    padding: 50px 0;
    text-align: center;

    // Text
    p {
        margin: 0;
    }

    // Video block inner
    .video-block-inner {
        width: 100%;
        max-width: 970px;
        height: 530px;
        margin: 0 auto;
        background-image: url('../img/video-bg.jpg');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        cursor: pointer;

        @media (max-width: 767px) {
            height: 400px;
        }

        // Absolute pos
        .absolute-pos {
            width: 100%;
            height: auto;
            bottom: 45px;
            left: 0;
            right: 0;
            position: absolute;
            text-align: center;

            // h2
            h2 {
                font-size: 48px;
                color: #fff;
                font-weight: 700;
                margin-top: 0;
                margin-bottom: 5px;
            }

            // Text
            p {
                font-size: 24px;
                color: #fff;
                text-align: center !important;
            }
        }

        // Play square
        &:before {
            width: 82px;
            height: 82px;
            background-color: #ffdc00;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            margin: auto;
            content: "";
            background-image: url('../img/play-black.svg');
            background-size: 14px 19px;
            background-position: center center;
            background-repeat: no-repeat;

            @media (max-width: 767px) {
                top: -30px;
            }
        }
    }
}

// References
section.references {
    background-color: #fff;
    padding-bottom: 5rem;

    @media (max-width: 992px) {
        padding: 60px 0;
    }

    // Item
    .reference-item {
        margin-bottom: 30px;
        height: 340px;

        @media (max-width: 992px) {
            height: 280px;
        }

        @media (max-width: 767px) {
            height: auto;
        }

        // Img
        img {
            width: 100%;
            border: 4px solid #f0f0f0;
            @include border-radius(5px);
            margin-bottom: 10px;
        }

        // Title
        .reference-item-title {
            font-family: "open-sans", sans-serif;
            font-size: 24px;
            color: #008ac8;
            font-weight: 400;
            margin-top: 10px;
            line-height: 1.2;

            @media (max-width: 992px) {
                font-size: 18px;
            }
        }

        // Link
        .reference-item-trigger a {
            color: #494949;
            font-size: 17px;
            font-weight: 300;
            text-decoration: underline;
        }
    }
}

section.reference {

    // Img
    img {
        width: 100%;
        border: 4px solid #f0f0f0;
        @include border-radius(5px);
        margin-bottom: 10px;
    }

    // content
    p {

        // Link
        a {
            color: #494949;
            text-decoration: underline;
        }
    }
}
.button-sm {
    &.c2a {
        background-color: #008AC8;
        color: white;
        margin-left: 50px;
        @media (max-width: 992px) {
            margin-left: 5px;
            font-size: 12px !important;
        }

        &:after {
            background-image: url("../img/icon-arrow-right-white.svg");
        }

        &:hover, &:focus {
            background-color: darken(#008AC8, 5%) !important;
        }
    }
}

.is-portal {
    nav, footer {
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
    }
    #spinner {
        position: absolute;
        left: 50%;
        top: 50%;

        display: block;
        width: 40px;
        height: 40px;

        background-image: url("../img/spinner.svg");
        background-size: 100% 100%;
        background-position: center;
        background-repeat: no-repeat;

        animation: spinner-load 1.5s linear infinite;
    }
}
@keyframes spinner-load {
    from {transform:rotateZ(0deg)}
    to {transform:rotateZ(360deg)}
}
