// Base colors
$base-color1:		rgb(0, 138, 200); // blue
$base-color2:		rgb(255, 220, 0); // yellow

// Background colors
$body-bg-color:     rgb(255, 255, 255); // white
$bg-color1:		    rgb(240, 240, 240); // lightgray
$bg-color2:         rgb(58, 58, 58); // dark gray

// Nav link colors
$nav-link:          rgb(118, 118, 118);