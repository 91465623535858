// Font smoothing
@if ($smoothing == true) {
	body {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
}

// Font weights
$weight-light: 			300;
$weight-regular: 		400;
$weight-medium: 		600;
$weight-bold: 			700;

// Font settings
$base-font-family: 		"open-sans",sans-serif;
$base-font-weight: 		$weight-light;
$base-font-size: 		17px;
$base-font-color1: 		rgb(73, 73, 73);
$base-font-color2: 		rgb(255, 255, 255);

// Headings
@mixin heading-size($type) {
	@if $type == h1 {
		font-size: 30px;
		@include breakpoint(sm) {
			font-size: 38px;
		}
		@include breakpoint(md) {
			font-size: 48px;
		}
	}
	@elseif $type == h2 {
		font-size: 30px;
		@include breakpoint(sm) {
			font-size: 38px;
		}
		@include breakpoint(md) {
			font-size: 48px;
		}
	}
	@else if $type == h3 {
		font-size: 28px;
		@include breakpoint(sm) {
			font-size: 32px;
		}
		@include breakpoint(md) {
			font-size: 42px;
		}
	}
	@else if $type == h4 {
		font-size: 20px;
		@include breakpoint(sm) {
			font-size: 30px;
		}
		@include breakpoint(md) {
			font-size: 20px;
		}
	}
	@else if $type == h5 {
		font-size: 14px;
		@include breakpoint(sm) {
			font-size: 14px;
		}
		@include breakpoint(md) {
			font-size: 24px;
		}
	}
	@else if $type == h6 {
		font-size: 12px;
		@include breakpoint(sm) {
			font-size: 12px;
		}
		@include breakpoint(md) {
			font-size: 18px;
		}
	}
}


$heading-font-family:	"myriad-pro",sans-serif;
$heading-font-weight: 	$weight-regular;
$heading-font-color1:	$base-color1;
$heading-font-color2:	rgb(255, 255, 255);