// Colors
$link-color-default:		$base-color1;
$link-color-hover:			darken($base-color1, 10%);
$link-color-active:			$base-color1;
$link-color-visited:		$base-color1;

// Decoration
$link-decoration-default:	none;
$link-decoration-hover:		none;
$link-decoration-active: 	underline;
$link-decoration-visited: 	underline;

// Font size
$link-size-default: 		$base-font-size;
$link-size-hover: 			$base-font-size;
$link-size-active: 			$base-font-size;
$link-size-visited: 		$base-font-size;