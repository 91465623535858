footer {
    position: relative;
    width: 100%;
    background-color: $bg-color2;
    padding: 50px 0 31px 0;
    color: $base-font-color2;

    .inner {
        margin-bottom: 20px;
        text-align: center;

        @include breakpoint(sm) {
            text-align: left;
        }
        @include breakpoint(md) {
            margin-bottom: 0;
        }
    }

    h4 {
        font-weight: $weight-bold;
        color: $heading-font-color2;
        margin-bottom: 2rem;
    }

    p {
        font-size: 1.063rem;
    }

    img {
        width: 3rem;
        height: 3rem;
        display: block;
        margin: 0 auto;

        @include breakpoint(sm) {
            width: 6rem;
            height: 6rem;
        }
    }

    a, a:visited, a:focus {
        color: $base-font-color2;

        &:hover {
            color: $base-font-color2;
            text-decoration: underline;
        }
    }

    .icon-facebook {
        background: url('../img/icon-facebook-white.svg') center center no-repeat;
        display: inline-block;
        width: 39px;
        height: 39px;
        text-indent: -9999999px;
        margin-right: 5px;
        opacity: 1;
        -webkit-transition: all 200ms;
        -moz-transition: all 200ms;
        -ms-transition: all 200ms;
        -o-transition: all 200ms;
        transition: all 200ms;

        &:hover {
            opacity: 0.7;
            -webkit-transition: all 200ms;
            -moz-transition: all 200ms;
            -ms-transition: all 200ms;
            -o-transition: all 200ms;
            transition: all 200ms;
        }
    }

    .icon-twitter {
        background: url('../img/icon-linkedin-white.svg') center center no-repeat;
        display: inline-block;
        width: 39px;
        height: 39px;
        margin-right: 8px;
        text-indent: -9999999px;
        opacity: 1;
        -webkit-transition: all 200ms;
        -moz-transition: all 200ms;
        -ms-transition: all 200ms;
        -o-transition: all 200ms;
        transition: all 200ms;

        &:hover {
            opacity: 0.7;
            -webkit-transition: all 200ms;
            -moz-transition: all 200ms;
            -ms-transition: all 200ms;
            -o-transition: all 200ms;
            transition: all 200ms;
        }
    }

    .icon-instagram {
        background: url('../img/icon-instagram-white.svg') center center no-repeat;
        display: inline-block;
        fill: #ffffff;
        width: 34px;
        height: 39px;
        text-indent: -9999999px;
        opacity: 1;
        -webkit-transition: all 200ms;
        -moz-transition: all 200ms;
        -ms-transition: all 200ms;
        -o-transition: all 200ms;
        transition: all 200ms;

        &:hover {
            fill: #ffffff;
            opacity: 0.7;
            -webkit-transition: all 200ms;
            -moz-transition: all 200ms;
            -ms-transition: all 200ms;
            -o-transition: all 200ms;
            transition: all 200ms;
        }
    }
    .copyright {
        margin-top: 20px;
        font-size: 13px;
        display: block;
        color: darken($base-font-color2, 10%);

        a {
            font-size: 13px;
            color: darken($base-font-color2, 20%);
        }
    }

    ul.list-inline {
        li {
            a {
                position: relative;

                &:after {
                    position: absolute;
                    content: '';
                    top: 4px;
                    right: -8px;
                    width: 1px;
                    height: 16px;
                    background-color: white;
                }
            }

            &:last-child {

                a {
                    &:after {
                        display: none;
                    }
                }
            }
        }
    }
}